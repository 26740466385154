<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div class="input-numbers card-btn-view  inputNo_13" [formGroup]="form">
  <div cols="2" class="grid-formui" [ngSwitch]="dataItem.controlType">
    <div *ngSwitchCase="1" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [formControlName]="dataItem.id"
          [type]="dataItem.type" class="form-control" placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" autocomplete="off" (blur)="isShowOptions()"
          (change)="onChangeEvent($event)">
        <mat-error *ngIf="form.get(dataItem.id).hasError('email')">
          Invalid Email
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="2" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <mat-select matTooltip="{{dataItem?.toolTipDescriptions}}" appendTo="body" [formControlName]="dataItem.id"
          [filter]="true" placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly"
          *ngIf="!dataItem.hidden" (selectionChange)="isShowOptions($event)" [compareWith]="compareCategoryObjects"
          class="form-control" disableOptionCentering>
          <mat-select-trigger *ngIf="componentName == 'RapidCustodiansEntryComponent' && selectedLegalValue">
            {{selectedLegalValue.label}}
            <a *ngIf="selectedLegalValue?.isLegalUrl" (click)="redirectUrl(selectedLegalValue.url)"
              style="float:right;color: #1a73f1;">{{selectedLegalValue?.url|translate}}</a>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="DropDownFilterCtrl" placeholderLabel="{{'Search'|translate}}"
              [noEntriesFoundLabel]=' "No Results match "+  DropDownFilterCtrl.value '></ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="!dataItem.sort">
            <div class="matselectn" *ngFor="let element of filteredDropDownList | async">
              <mat-option *ngIf="!dataItem.info && componentName != 'mappingDefaultCoaTaxClassification'"
                [value]="element ? element.value:null" [disabled]="element?.disabled">
                {{element?.label|translate}} <span class="textchange"
                  *ngIf="componentName == 'RapidCustodiansEntryComponent'&& element?.value.aliasOfCustodianExchangeWalletBank">
                  ({{element?.value.aliasOfCustodianExchangeWalletBank|translate}}) </span>


                <a *ngIf="element?.isLegalUrl" (click)="redirectUrl(element.url)"
                  style="float:right;color: #1976d2;">{{element?.url|translate}}</a>
              </mat-option>
              <mat-option *ngIf="componentName == 'mappingDefaultCoaTaxClassification'" [value]="element ? element:null">
                {{element?.label|translate}}   
                         </mat-option>

              <mat-option *ngIf="dataItem.info && componentName != 'mappingDefaultCoaTaxClassification' " [value]="element ? element.value:null">
                {{element?.label|translate}}  <span *ngIf="element?.label === 'Yes'"><mat-icon>info</mat-icon><b><i>The effect of this will be reflected in the income allocation</i></b> </span>


                <a *ngIf="element?.isLegalUrl" (click)="redirectUrl(element.url)"
                  style="float:right;color: #1976d2;">{{element?.url|translate}}</a>
              </mat-option>
              <span *ngIf="componentName != 'RapidCustodiansEntryComponent'&& !selectedLegalValue"
                class="customtooltip">{{element?.label|translate}}</span>
              <span *ngIf="componentName == 'RapidCustodiansEntryComponent'&& element?.value.aliasOfCustodianExchangeWalletBank"
                class="customtooltip customtooltipedit">
                {{element?.value.aliasOfCustodianExchangeWalletBank|translate}}</span>
            </div>
            <mat-option (click)="$event.stopPropagation();navigatePage()"
              *ngIf="dataItem.isshowCreateNewOption && filterdDataLength()==0 && !filteredDropDownitemList[0]">
              {{'create_new'|translate}} <span class="material-icons-outlined addSelectOption">
                add_circle_outline
              </span>
            </mat-option>
          </ng-container>
          <ng-container *ngIf="dataItem.sort">
            <div class="matselectn" *ngFor="let element of filteredDropDownList | async">
              <mat-option [value]="element ? element.value:null">
                {{element?.label|translate}}

              </mat-option>
              <span class="customtooltip">{{element?.label|translate}}</span>
            </div>
          </ng-container>
        </mat-select>
        <span *ngIf="dataItem.showAddButton" (click)="$event.stopPropagation();navigatePage()"
          class="material-icons-outlined addSelectOption">
          add_circle_outline
        </span>
        <span *ngIf="dataItem.showDownloadButton" (click)="$event.stopPropagation();toggleFileEvent('download')"
          class="material-icons-outlined addSelectOption">
          file_download
        </span>
        <span *ngIf="dataItem.showVisibility" (click)="$event.stopPropagation();toggleFileEvent('Visibility')"
          class="material-icons-outlined addSelectOption">
          visibility
        </span>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="3" [id]="dataItem.id" class="date-picker" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [matDatepicker]="picker"
          placeholder="{{dataItem.label|translate}}" [formControlName]="dataItem.id" class="form-control md-input "
          [showIcon]="true" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" [value]="dataItem.value"
          (dateChange)="isShowOptions()" (click)="picker.open()" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="4" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <label [hidden]="dataItem.hidden"> {{dataItem.label|translate}}<sup *ngIf="dataItem.required"
          style="color:red;">*</sup></label><br>
      <input *ngIf="!dataItem.multiple" [formControlName]="dataItem.id" [id]="dataItem.id" [type]="'file'"
        class="form-control " style="padding-top: 0px; padding-bottom: 1em;" [readonly]="dataItem.readonly"
        (change)="onFileSelected($event)" [hidden]="dataItem.hidden">

      <input *ngIf="dataItem.multiple && componentName =='investorStatementAIF'" [formControlName]="dataItem.id"
        [id]="dataItem.id" [type]="'file'" class="form-control " style="padding-top: 0px; padding-bottom: 1em;"
        multiple="multiple" [readonly]="dataItem.readonly" (change)="onFileSelected($event)" [hidden]="dataItem.hidden">
    </div>

    <div *ngSwitchCase="5" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" type="number" matInput [formControlName]="dataItem.id"
          [showButtons]="true" placeholder="{{dataItem.label|translate}}" class="form-control md-input "
          [readonly]="dataItem.readonly" (change)="onChangeEvent($event)" *ngIf="!dataItem.hidden"
          (blur)="isShowOptions()" autocomplete="off">
      </mat-form-field>
    </div>

    <div *ngSwitchCase="6" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input type="time" matInput [formControlName]="dataItem.id" placeholder="{{dataItem.label|translate}}"
          (change)="onChangeEvent($event)" class="form-control md-input " [readonly]="dataItem.readonly"
          *ngIf="!dataItem.hidden">
      </mat-form-field>
    </div>

    <!--auth: VG for Select All  -->
    <div *ngSwitchCase="7" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2">
      <span class="p-float-label" [ngClass]="{'disable': dataItem.disabled}">
        <p-multiSelect #multiselectforpopup appendTo="body" [options]="dataItem.fill" [formControlName]="dataItem.id"
          [filter]="true" [selectionLimit]="dataItem.selectionLimit" optionLabel="label" [optionValue]="value"
          [resetFilterOnHide]="true" *ngIf="!dataItem.hidden" (onChange)="isShowOptions()" filterBy="label,alias"
          selectedItemsLabel="{0} items selected" matTooltip=" {{selectedDropDownToolTip|translate}}">
          <ng-template let-dataItem pTemplate="item">
            <div class="title-tip" tooltipPosition="right">
              {{dataItem.label|translate}}
              <span class="mytooltip">{{ dataItem.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
        <span *ngIf="dataItem.showAddButton && !dataItem.disabled" (click)="$event.stopPropagation();navigatePage()"
          class="material-icons-outlined addSelectOption">
          add_circle_outline
        </span>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></label><br>
      </span>
    </div>
    <!--  -->

    <div *ngSwitchCase="8" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" furyCustomDateFormatMonth>
        <mat-label appRemoveAriaOwns class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input matInput [matDatepicker]="dp" [formControlName]="dataItem.id" placeholder="{{dataItem.label|translate}}"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden"
          (onChange)="isShowOptions()" autocomplete="off" (click)="dp.open()">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="9" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style"
      class="pl-2 pt-2 pb-2 formcheck-box">
      <mat-checkbox [formControlName]="dataItem.id" [id]="dataItem.id" [type]="checkbox" inputId="taxLotsByAccount"
        [binary]="dataItem.binary" (change)="isShowOptions()" *ngIf="!dataItem.tooltip"></mat-checkbox>
      <mat-checkbox [formControlName]="dataItem.id" [id]="dataItem.id" [type]="checkbox" inputId="taxLotsByAccount"
        [binary]="dataItem.binary" (change)="isShowOptions()" matTooltip="{{ dataItem.tooltipTxt | translate }}"
        *ngIf="dataItem.tooltip"></mat-checkbox>
      <mat-label class="label-font" *ngIf="!dataItem.hidden">
        {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
      </mat-label>
    </div>

    <div *ngSwitchCase="10" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <mat-date-range-input [formGroup]="form" [rangePicker]="picker" placeholder="Custome Date Range">
          <input matStartDate placeholder="Start date" [formControlName]="dataItem.id" #s (click)="picker.open()"
            (dateChange)="selectdate(s)">
          <input matEndDate placeholder="End date" [formControlName]="dataItem.id">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="11" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <button mat-raised-button color="primary" (click)="isShowOptions()"
        class="mat-mini-fab mat-button-base mat-primary" *ngIf="!dataItem.tooltip">
        <span class="material-icons-outlined">
          <i class="material-icons" title="{{dataItem.titel |translate}}" data-md-tooltip="{{'add_tooltip'|translate}}">
            add
          </i>
        </span>
      </button>
      <button mat-raised-button color="primary" (click)="isShowOptions()"
        matTooltip="{{ dataItem.tooltipTxt | translate }}" *ngIf="dataItem.tooltip">
        <i class="material-icons" data-md-tooltip="{{dataItem.tooltipTxt | translate}}">
          {{dataItem.label | translate}}
        </i>
      </button>
    </div>

    <div *ngSwitchCase="12" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input type="datetime-local" matInput [formControlName]="dataItem.id" placeholder="MM/DD/YY 00:00"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden">
      </mat-form-field>
    </div>

    <div *ngSwitchCase="13" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style"
      class="ml-2 inputNumber">
      <span class="p-float-label">
        <p-inputNumber id="dataItem.id" [formControlName]="dataItem.id" [showButtons]="dataItem.showButtons"
          [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden" [mode]="dataItem.mode"
          [minFractionDigits]="dataItem.minFractionDigits" inputId="dataItem.inputId" [min]="dataItem.min"
           [max]="dataItem.max" [maxFractionDigits]="dataItem.maxFractionDigits" [suffix]="dataItem.suffix" (onBlur)="isShowOptions()">
        </p-inputNumber>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
      </span>
    </div>

    <div *ngSwitchCase="14" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <div class="mat-form-field">
        <mat-label class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label><br>
        <mat-radio-group matTooltip="{{dataItem?.toolTipDescriptions}}" [formControlName]="dataItem.id" class="mt-2">
          <br>
          <mat-radio-button *ngFor="let el of dataItem.fill" [value]="el.value" (change)="onRadioBtnSelected($event)">
            {{el.label|translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div *ngSwitchCase="15" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <!-- //textarea without autosuggestions FS-Notes config FSA-108-->
      <mat-form-field *ngIf="!dataItem.mention" class="textarea-height" appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <textarea matInput [formControlName]="dataItem.id" [id]="dataItem.id" class="form-control"
          placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          autocomplete="off" (change)="isShowOptions()" matInput></textarea>
      </mat-form-field>
      <!-- //textarea with autosuggestions FS-Notes config FSA-108 -->
      <mat-form-field *ngIf="dataItem.mention" class="textarea-height" appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <textarea matInput [formControlName]="dataItem.id" [id]="dataItem.id" class="form-control"
          placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          autocomplete="off" (change)="isShowOptions()" [mentionConfig]="dataItem.mentionConfig"
          [mentionListTemplate]="mentionListTemplate" matInput></textarea>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="16" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-label class="label-font"> {{dataItem.label |translate}}</mat-label>
    </div>

    <div *ngSwitchCase="17" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" furyCustomDateFormatYear>
        <mat-label appRemoveAriaOwns class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matInput [matDatepicker]="dp" [formControlName]="dataItem.id" placeholder="{{dataItem.label|translate}}"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden"
          (onChange)="isShowOptions()" autocomplete="off" (click)="dp.open()">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (yearSelected)="onlyYearHandler($event,dp)"
          panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="18" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2">
      <span>
        <label for="dataItem.id" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
        <p-toggleButton [formControlName]="dataItem.id" onLabel="{{'active'|translate}}"
          offLabel="{{'in_active'|translate}}"></p-toggleButton>
      </span>
    </div>

    <div *ngSwitchCase="19" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matInput [formControlName]="dataItem.id" [type]="inputType" class="form-control"
          placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          autocomplete="new-password" (change)="isShowOptions()" (blur)="isShowOptions()">
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="">
          <mat-icon *ngIf="visible">visibility</mat-icon>
          <mat-icon *ngIf="!visible">visibility_off</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="20" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span> https://
        <mat-form-field class="domain" appearance="outline">
          <mat-label class="label-font" [hidden]="dataItem.hidden">
            {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
          </mat-label>
          <input matInput [formControlName]="dataItem.id" [type]="dataItem.type" [readonly]="dataItem.readonly"
            [hidden]="dataItem.hidden" autocomplete="off" (change)="isShowOptions()" (blur)="isShowOptions()">
        </mat-form-field>.{{dataItem.domainName}}
      </span>
    </div>

    <div *ngSwitchCase="21" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matInput [formControlName]="dataItem.id" [type]="dataItem.type" class="form-control"
          style="cursor:pointer" placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly"
          [hidden]="dataItem.hidden" autocomplete="off" (click)="isShowOptions()" (change)="isShowOptions()">
      </mat-form-field>
    </div>

    <div *ngSwitchCase="22" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [formControlName]="dataItem.id"
          pattern="^[A-Za-z0-9? ,_-]+$" [type]="dataItem.type" class="form-control"
          placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          autocomplete="off" (blur)="isShowOptions()">
          <mat-error *ngIf="form.get(dataItem.id).hasError('pattern') && componentName == 'RapidCustodiansEntryComponent'">
            Please Enter Valid {{dataItem.label|translate}}
          </mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="23" [id]="dataItem.id" class="date-picker" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [matDatepicker]="picker"
          placeholder="{{dataItem.label|translate}}" [formControlName]="dataItem.id" class="form-control md-input "
          [showIcon]="true" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" [value]="dataItem.value"
          (dateChange)="isShowOptions()" (click)="picker.open()" [min]="dataItem.minDate" [max]="dataItem.maxDate"
          autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="24" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span class="p-float-label">
        <p-calendar #myCalendar [formControlName]="dataItem.id" [timeOnly]="true" [readonly]="dataItem.readonly"
          [hidden]="dataItem.hidden" timeFormat="HH:mm:ss" showTime="true" [hourFormat]="24" [showSeconds]="true"
          showIcon="true">
          <p-footer class="timestamp-footer"> <button (click)="myCalendar.toggle()">Set</button></p-footer>
        </p-calendar>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
      </span>
    </div>

    <div *ngSwitchCase="25" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label">
        {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
      </label>
      <span class="p-float-label">
        <input type="text" matInput ngxDaterangepickerMd [formControlName]="dataItem.id" [readonly]="dataItem.readonly"
          [hidden]="dataItem.hidden" [autoApply]="true" id="calendar-input" [alwaysShowCalendars]="true"
          [showRangeLabelOnInput]="true" [drops]="up" placeholder="Date Range" class="datepick" />
        <span for="calendar-input" class="date-range-picker-icon fa fa-calendar"></span>
      </span>
    </div>

    <div *ngSwitchCase="26" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span style="cursor:pointer;" class="material-icons-outlined plusminuscircle" (click)="isShowOptions()">
        <i class="material-icons cross-icon" title="{{dataItem.titel |translate}}" data-md-tooltip="{{'add_tooltip'|translate}}">
          highlight_off </i>
      </span>
    </div>

    <div *ngSwitchCase="27" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span style="cursor:pointer;" class="material-icons-outlined plusminuscircle" (click)="isShowOptions()">
        <i class="material-icons" title="{{dataItem.titel |translate}}" data-md-tooltip="{{'add_tooltip'|translate}}">
          add_circle_outline </i>
      </span>
    </div>

    <div *ngSwitchCase="28" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span style="padding-left: 10px;color: red;"> {{dataItem.label |translate}}</span>
      <span> {{dataItem.label1 |translate}}</span>
      <span> {{dataItem.label2 |translate}}</span>
    </div>

    <div *ngSwitchCase="29" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2">
      <span class="p-float-label">
        <p-multiSelect appendTo="body" [(ngModel)]="dataItem.value" [options]="dataItem.fill"
          [formControlName]="dataItem.id" [filter]="true" [selectionLimit]="dataItem.selectionLimit" optionLabel="label"
          [optionValue]="value" [resetFilterOnHide]="true" *ngIf="!dataItem.hidden" optionDisabled="inactive"
          [showToggleAll]="false" (onChange)="isShowOptionsCst($event)" filterBy="label,alias"
          selectedItemsLabel="{0} items selected">
          <ng-template let-dataItem pTemplate="item">
            <div class="title-tip" tooltipPosition="right">
              {{dataItem.label|translate}}
              <span class="mytooltip">{{ dataItem.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
        <span *ngIf="dataItem.showAddButton" (click)="$event.stopPropagation();navigatePage()"
          class="material-icons-outlined addSelectOption">
          add_circle_outline
        </span>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
      </span>
    </div>
    <div *ngSwitchCase="30" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" furyCustomDateFormatMonth>
        <mat-label appRemoveAriaOwns class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input matInput [matDatepicker]="dp" [formControlName]="dataItem.id" placeholder="{{dataItem.label|translate}}"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden"
          (onChange)="isShowOptions()" autocomplete="off" (click)="dp.open()" [min]="dataItem.minMonth"
          [max]="dataItem.maxMonth">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>
    <!-- /** Start
    * Function that allow to save dynamic option to dropdown FSA-119
    * @author Ashok Kumar
    */ -->
    <div *ngSwitchCase="31" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" *ngIf="dataItem.addType == 'selection'">
        <mat-label appRemoveAriaOwns class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <mat-select matTooltip="{{dataItem?.toolTipDescriptions}}" appendTo="body" [formControlName]="dataItem.id"
          [filter]="true" placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly"
          *ngIf="!dataItem.hidden" (selectionChange)="isShowOptions()" [compareWith]="compareCategoryObjects"
          class="form-control" disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search [formControl]="DropDownFilterCtrl" placeholderLabel="{{'Search'|translate}}"
              [noEntriesFoundLabel]=' "No Results match "+  DropDownFilterCtrl.value '></ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="!dataItem.sort">


            <div class="matselectn" *ngFor="let element of filteredDropDownList | async |  orderBy:'label'">
              <mat-option [value]="element ? element.value:null">
                {{element?.label|translate}}

              </mat-option>
              <span class="customtooltip">{{element?.label|translate}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="dataItem.sort">
            <div class="matselectn" *ngFor="let element of filteredDropDownList | async">
              <mat-option [value]="element ? element.value:null">
                {{element?.label|translate}}

              </mat-option>
              <span class="customtooltip">{{element?.label|translate}}</span>
            </div>
          </ng-container>
        </mat-select>
        <span *ngIf="dataItem.showAddButton" (click)="$event.stopPropagation();addDataItem();"
          class="material-icons-outlined addSelectOption">
          add_circle_outline
        </span>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="dataItem.addType == 'input'">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [formControlName]="dataItem.id"
          [type]="dataItem.type" class="form-control" placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" autocomplete="off" (blur)="isShowOptions()"
          (change)="onChangeEvent($event)">
        <span *ngIf="dataItem.showAddButton" (click)="$event.stopPropagation(); saveDataItem();"
          class="material-icons-outlined addSelectOption">
          check_circle_outline
        </span>
        <span *ngIf="dataItem.showAddButton" style="right: 0rem;" (click)="$event.stopPropagation();cancelDataItem();"
          class="material-icons-outlined addSelectOption">
          highlight_off
        </span>
      </mat-form-field>
    </div>
    <!-- /** End
    * Function that allow to save dynamic option to dropdown FSA-119
    * @author Ashok Kumar
    */ -->
    <!-- date picker formate MM-DD-YYYY -->

    <div *ngSwitchCase="32" [id]="dataItem.id" class="date-picker" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" furyCustomDate>
        <mat-label appRemoveAriaOwns class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [matDatepicker]="customDate"
          pickerFormat="MM-DD-YYYY" placeholder="{{dataItem.label|translate}}" [formControlName]="dataItem.id"
          class="form-control md-input " [showIcon]="true" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          [value]="dataItem.value" (dateChange)="isShowOptions()" (click)="customDate.open()" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="customDate"></mat-datepicker-toggle>
        <mat-datepicker #customDate></mat-datepicker>
      </mat-form-field>
    </div>


    <div *ngSwitchCase="33" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span style="color: red;"> {{dataItem.label |translate}}</span>
      <span> {{dataItem.label1 |translate}}</span>
      <span> {{dataItem.label2 |translate}}</span>
    </div>

    <!-- /** start
    * Function that allow masking value
    * @author Sachin Marwal
    */ -->
    <div *ngSwitchCase="34" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" [type]="dataItem.type" matInput
          [formControlName]="dataItem.id" class="form-control" placeholder="{{dataItem.label|translate}}"
          autocomplete="off" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" [value]="dataItem.value"
          minlength="9" maxlength="9" (change)="isShowOptions()" [disabled]="!maskVisible || dataItem.disabled" (blur)="isShowOptions()"
          numbersOnly />
        <button type="button" mat-icon-button matSuffix matTooltip="">
          <mat-icon
            (click)="form.controls[dataItem.id].value ? maskingPassword(form.controls[dataItem.id].value) : '';$event.stopPropagation()"
            *ngIf="maskVisible">visibility
          </mat-icon>
          <mat-icon
            (click)="form.controls[dataItem.id].value ? unMaskPassword(dataItem.id) : '';$event.stopPropagation()"
            *ngIf="!maskVisible">visibility_off
          </mat-icon>
        </button>
      </mat-form-field>
    </div>


    <div *ngSwitchCase="35" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <!-- <span style="color: red;"> {{dataItem.label |translate}}</span> -->
      <!-- <span> {{dataItem.label1 |translate}}</span>
      <span> {{dataItem.label2 |translate}}</span> -->
      <div class="info-msg">
        <span class="material-icons-outlined" style="color: grey !important;">info</span>
        <span class="msg">
          The pre-filled data in following fields is as per the information
          available in our records. Please verify such information before
          proceeding.
        </span>
      </div>
    </div>
    <div *ngSwitchCase="39" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2" (change)="isShowOptions()" (click)="isShowOptions()" >
      <mat-icon  mat-icon-button  matInput style="cursor: pointer;" >manage_search</mat-icon>
       
      </div>
    <div *ngSwitchCase="40" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2">
      <span>
        <label for="dataItem.id" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
      </span>
    </div>
    <div *ngSwitchCase="41" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <div class="mat-form-field">
        <mat-radio-group matTooltip="{{dataItem?.toolTipDescriptions}}" [formControlName]="dataItem.id">
          <mat-radio-button *ngFor="let el of dataItem.fill" [value]="el.value" (change)="isShowOptions()">
            {{el.label|translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div *ngSwitchCase="42" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" pattern="^[1-9]$|^[1-9][0-9]$|^(100)$" type="number"
          matInput [formControlName]="dataItem.id" [showButtons]="true" placeholder="{{dataItem.label|translate}}"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden"
          (blur)="isShowOptions()" autocomplete="off" (change)="onChangeEvent($event)">
        <mat-error *ngIf="form.get(dataItem.id).hasError('pattern')">
          Please enter rank between 1 to 100
        </mat-error>
      </mat-form-field>

    </div>
    <div *ngSwitchCase="43" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline" furyCustomDateFormatMonthYear>
        <mat-label appRemoveAriaOwns class="label-font" *ngIf="!dataItem.hidden"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></mat-label>
        <input matInput [matDatepicker]="dp" [formControlName]="dataItem.id" placeholder="{{dataItem.label|translate}}"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden"
          (onChange)="isShowOptions()" autocomplete="off" (click)="dp.open()">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthYearHandler($event, dp)" panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="44" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style"
      class="pl-2 pt-2 pb-2 formcheck-box" data-toggle="tooltip" matTooltipPosition="above"
      matTooltip="Disabled ROR in Account Statement">
      <mat-checkbox [formControlName]="dataItem.id" [id]="dataItem.id" [type]="checkbox" inputId="taxLotsByAccount"
        [binary]="dataItem.binary" (change)="isShowOptions()" *ngIf="!dataItem.tooltip"></mat-checkbox>
      <mat-checkbox [formControlName]="dataItem.id" [id]="dataItem.id" [type]="checkbox" inputId="taxLotsByAccount"
        [binary]="dataItem.binary" (change)="isShowOptions()" matTooltip="{{ dataItem.tooltipTxt | translate }}"
        *ngIf="dataItem.tooltip"></mat-checkbox>
      <mat-label class="label-font" *ngIf="!dataItem.hidden">
        {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
      </mat-label>
    </div>
    <!-- /** End
    * Function that allow masking value
    * @author Sachin Marwal
    */ -->
    <div *ngSwitchCase="45" [id]="dataItem.id" class="p-drop" [class]="dataItem.class" [style]="dataItem.style">
      <span class="p-float-label" [ngClass]="{'disable': dataItem.disabled}"
        matTooltip="{{dataItem?.toolTipDescriptions}}">
        <p-dropdown appendTo="body" [options]="filteredDropDownList | async" [formControlName]="dataItem.id"
          optionLabel="label" optionValue="value" [required]="dataItem.required" [autoDisplayFirst]="false"
          [filter]="true" filterBy="label,alias" [showClear]="false" [name]="dataItem.id" [readonly]="dataItem.readonly"
          [resetFilterOnHide]="true" *ngIf="!dataItem.hidden" (onChange)="isShowOptions()" [virtualScroll]="true"
          (onFilter)="pDropFilter($event)" (onShow)="pDropOnShow($event)" [itemSize]="35" [scrollHeight]="scrollHeight">
          <ng-template let-item pTemplate="selectedItem">
            <span>{{item.label|translate}}</span>
          </ng-template>
          <ng-template let-dataItem pTemplate="item">
            <div class="title-tip" tooltipPosition="bottom">
              {{dataItem.label|translate}}
              <span class="mytooltip">{{ dataItem.label |translate}}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <label>{{ dataItem.label|translate }}<sup *ngIf="dataItem.required" style="color:red;">*</sup></label>
        <span *ngIf="dataItem.showAddButton" (click)="$event.stopPropagation();navigatePage()"
      class="material-icons-outlined addSelectOptions">
      add_circle_outline
    </span>
      </span>
     
    </div>


    <!-- /** start
    * control type for IP address validation
    * @author Seema jakhar
    */ -->
    <div *ngSwitchCase="46" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input [type]="dataItem.type" class="form-control" matInput placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" [formControlName]="dataItem.id" [hidden]="dataItem.hidden" autocomplete="off"
          (blur)="isShowOptions()" (change)="onChangeEvent($event)"
          pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
        <mat-error *ngIf="form.get(dataItem.id).hasError('pattern')">
          Please Enter Valid Ip Address
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="47" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input [type]="dataItem.type" class="form-control" matInput placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" [formControlName]="dataItem.id" [hidden]="dataItem.hidden" autocomplete="off"
          (blur)="isShowOptions()" (change)="onChangeEvent($event)"
          pattern="^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(,\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})*$">
        <mat-error *ngIf="form.get(dataItem.id).hasError('pattern')">
          Please Enter Valid Ip Address
        </mat-error>
      </mat-form-field>
    </div>


    <div class="radioBtn_input" *ngSwitchCase="48" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <div class="mat-form-field">
        <mat-label class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label><br>
        <mat-radio-group matTooltip="{{dataItem?.toolTipDescriptions}}" [formControlName]="dataItem.id" class="mt-2">
          <br>
          <mat-radio-button *ngFor="let el of dataItem.fill" [value]="el.value" (change)="onRadioBtnSelected($event)">
            {{el.label|translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!-- /** start
    * control type for taxID's value with masking and copy paste options
    * @author Seema jakhar
    */ -->
    <div *ngSwitchCase="49" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matInput [formControlName]="dataItem.id" [type]="inputType" class="form-control"
          placeholder="{{dataItem.label|translate}}" [readonly]="dataItem.readonly" [hidden]="dataItem.hidden"
          autocomplete="new-password" (change)="isShowOptions()" (blur)="isShowOptions()" minlength="9" maxlength="9"
          numbersOnly>
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="">
          <mat-icon *ngIf="visible">visibility</mat-icon>
          <mat-icon *ngIf="!visible">visibility_off</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <!-- /** End
    * control control type for taxID's value with masking and copy paste options
    * @author Seema jakhar
    */ -->


     <!-- /** start
    * control type for increment and decrement number
    * @author Balesh
    */ -->
    <div *ngSwitchCase="51" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span class="p-float-label">
        <p-inputNumber [formControlName]="dataItem.id" [max]="dataItem.max" [min]="dataItem.min" [showButtons]="true"
          buttonLayout="horizontal" inputId="horizontal" placeholder="{{dataItem.label|translate}}"
          spinnerMode="horizontal" [step]="dataItem.step" [readonly]="dataItem.readonly"
          (change)="onChangeEvent($event)" *ngIf="!dataItem.hidden" (blur)="isShowOptions()" autocomplete="off"
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"> </p-inputNumber>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </label><br>
      </span>
    </div>
 <!-- /** End
    * control type for increment and decrement number
    * @author Balesh
    */ -->
    
    <div *ngSwitchCase="52" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [formControlName]="dataItem.id"
          [type]="dataItem.type" class="form-control" placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" [hidden]="dataItem.hidden" autocomplete="off" (focus)="dataItem.eventType == ''">
        <button type="button" mat-icon-button matSuffix color="primary"
          (click)="$event.stopPropagation();toggleFileEvent('download')" matTooltip="Download">
          <mat-icon>file_download</mat-icon>
        </button>
        <button type="button" *ngIf="dataItem.showDelButton" mat-icon-button matSuffix color="error"
          (click)="$event.stopPropagation();toggleFileEvent('delete')" matTooltip="Delete">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="53" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <label [hidden]="dataItem.hidden" *ngIf="dataItem.label">
        {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
      </label><br *ngIf="dataItem.label">
      <input *ngIf="!dataItem.multiple" [formControlName]="dataItem.id" [id]="dataItem.id" [type]="'file'"
        class="form-control " style="padding-top: 6px;" [readonly]="dataItem.readonly" (change)="onFileSelected($event)"
        [hidden]="dataItem.hidden" [accept]="dataItem.accept">
    </div>

    <div *ngSwitchCase="54" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <span> {{dataItem.label |translate}}</span><sup *ngIf="dataItem.isshowrequired" style="color:red;">*</sup>
    </div>
  <!-- /** End
    * control type for Not Allow Space
    */ -->
    <div *ngSwitchCase="55" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label class="label-font" [hidden]="dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input matTooltip="{{dataItem?.toolTipDescriptions}}" matInput [formControlName]="dataItem.id"
          [type]="dataItem.type" class="form-control" placeholder="{{dataItem.label|translate}}"
          [readonly]="dataItem.readonly" (keydown.space)="$event.preventDefault()" [hidden]="dataItem.hidden"
          autocomplete="off" (blur)="isShowOptions()" (change)="onChangeEvent($event)" (input)="ChangeEvent($event)">
        <mat-error *ngIf="form.get(dataItem.id).hasError('email')">
          Invalid Email
        </mat-error>
      </mat-form-field>
    </div>
    <!-- text-area autosuggestions template FS-Notes config FSA-108 -->

    <div *ngSwitchCase="56" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <p><span class="material-icons-outlined">info</span>
        <span> {{dataItem.label |translate}}</span>
        <b *ngIf="dataItem.isBold">&nbsp;{{dataItem.label1 |translate}}&nbsp;</b>
        <span> {{dataItem.label2 |translate}}</span>
        <b *ngIf="dataItem.isBold">&nbsp;{{dataItem.label1 |translate}}</b>.
      </p>
    </div>

    <div *ngSwitchCase="57" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-form-field appearance="outline">
        <mat-label   class="label-font" *ngIf="!dataItem.hidden">
          {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
        </mat-label>
        <input type="datetime-local" type="time"  step="2" matInput [formControlName]="dataItem.id" placeholder="00:00:00"
          class="form-control md-input " [readonly]="dataItem.readonly" *ngIf="!dataItem.hidden">
      </mat-form-field>
    </div>
    <div *ngSwitchCase="58" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style" class="ml-2">
      <span class="p-float-label" [ngClass]="{'disable': dataItem.disabled}">
        <p-multiSelect #multiselectforpopup appendTo="body" [options]="dataItem.fill" [formControlName]="dataItem.id" [virtualScroll]="true"
        [itemSize]="30" [filter]="true" [selectionLimit]="dataItem.selectionLimit" optionLabel="label" [optionValue]="value"
       [filter]="true"   [resetFilterOnHide]="true" *ngIf="!dataItem.hidden" (onChange)="isShowOptions()" filterBy="label,alias"
          selectedItemsLabel="{0} items selected" matTooltip=" {{selectedDropDownToolTip|translate}}">
          <ng-template let-dataItem pTemplate="item">
            <div class="title-tip" tooltipPosition="right">
              {{dataItem.label|translate}}
              <span class="mytooltip">{{ dataItem.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
        <span *ngIf="dataItem.showAddButton && !dataItem.disabled" (click)="$event.stopPropagation();navigatePage()"
          class="material-icons-outlined addSelectOption">
          add_circle_outline
        </span>
        <label for="dataItem.id" *ngIf="!dataItem.hidden" class="p-float-label"> {{dataItem.label|translate}}<sup
            *ngIf="dataItem.required" style="color:red;">*</sup></label><br>
      </span>
    </div>
    <div *ngSwitchCase="59" [id]="dataItem.id" [class]="dataItem.class" [style]="dataItem.style">
      <mat-label class="label-font" *ngIf="!dataItem.hidden">
        {{dataItem.label|translate}}<sup *ngIf="dataItem.required" style="color:red;">*</sup>
      </mat-label>
       <mat-slide-toggle (change)="isShowOptions()"  matInput [formControlName]="dataItem.id" [id]="dataItem.id"></mat-slide-toggle>
      </div>
  </div>
</div>








<ng-template #mentionListTemplate let-item="item">
  <div>
    <span style="font-size: 14px;">{{item.name}}</span>
    <span style="font-size: 10px; white-space: normal;">{{item.description}}</span>
  </div>
</ng-template>