/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, forkJoin, switchMap } from "rxjs";
import { LocalStoreService } from "../commonServices/local-store.service";
import { Subject } from "rxjs";
import moment from "moment";
import { CommonService } from "../commonServices/common.service";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: "root"
})
export class GridService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  public gridViewData = {};
  subject = new Subject<any>();
  viewbtnsubject = new Subject<any>();
  uploadbtnsubject = new Subject<any>();
  uploadbtnUsersubject = new Subject<any>();
  isSubscribed = false;
  istoggleChange = false;
  exchangeList: any;
  FSInvestorData: any;
  taxAllactionData:any;
  FSPortFolioData: any;
  fsChartOfAccounts: any;
  currentUser: any;
  fundList: any;
  public tempalateGenericData  = {};
  private _configData: any = {};
  constructor(private store: LocalStoreService, private commonService: CommonService, private http: HttpClient, private cookieService: CookieService) { }

  setTriggerMethod(data) {
    if (data)
      this.subject.next(data)
  }

  GetTriggerMethod() {
    return this.subject.asObservable();
  }

  /**
   * set investor data for FS-investor FSA-425
   * @author   Ashok Kumar
   */
  setFSInvestorData(data) {
    if (data)
      this.FSInvestorData = data;
  }

  /**
   * get investor data for FS-investor FSA-425
   * @author   Ashok Kumar
   */
  getFSInvestorData() {
    return this.FSInvestorData;
  }

  /**
   * set investor data for FS-investor FSA-425
   * @author   Balesh Kumar
   */
  setFSPortfolioData(data) {
    if (data)
      this.FSPortFolioData = data;
  }

  /**
   * get investor data for FS-investor FSA-425
   * @author   Balesh Kumar
   */
  getFSPortFolioData() {
    return this.FSPortFolioData;
  }
  settaxAllocationData(data) {
    if (data)
      this.subject.next(data)
  }

  /**
   * get investor data for FS-investor FSA-425
   * @author   Balesh Kumar
   */
  gettaxAllocationData() {
    return this.subject.asObservable();
  }
  setFsChartOfAccounts(data) {
    if (data) {
      this.fsChartOfAccounts = data
    }
  }
  getFsChartOfAccounts() {
    return this.fsChartOfAccounts;
  }
  /**
   * set Tempalate Generic Data( fontName,fontSize ) FSA-728
   * @author  Utkarsh
   */
  setFSTempalateGenericData(data) {
    if (data)
      this.tempalateGenericData = data;
  }

  /**
   * get Tempalate Generic Data( fontName,fontSize ) FSA-728
   * @author   Utkarsh
   */
  getFSTempalateGenericData() {
    return this.tempalateGenericData;
  }


   getConfigData(): any {
    return this._configData;
  }
   setConfigData(data: any) {
    if (data) {
      this._configData = data;
    }
  }


  seteditBtnMethod(data) {
    if (data)
      this.viewbtnsubject.next(data)
  }

  getEditTriggerMethod() {
    return this.viewbtnsubject.asObservable();
  }

  setUserActionMethod(data) {
    if (data)
      this.uploadbtnUsersubject.next(data)
  }

  getUserTriggerMethod() {
    return this.uploadbtnUsersubject.asObservable();
  }

  setSetupActionMethod(data) {
    if (data)
      this.uploadbtnsubject.next(data)
  }

  getsetupTriggerMethod() {
    return this.uploadbtnsubject.asObservable();
  }

  getGridData(gridDataUrl, cbs, cbe) {
    this.headerReset();
    this.http.get(gridDataUrl, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getGridDataPost(gridDataUrl, cbs, cbe, data?) {
    if (data) {
      this.http.post<any>(gridDataUrl, data, this.httpOptions)
        .pipe(res => res)
        .subscribe(result => {
          cbs(result);
        }, error => {
          cbe(error);
        })
    } else {
      this.http.post<any>(gridDataUrl, this.httpOptions)
        .pipe(res => res)
        .subscribe(result => {
          cbs(result);
        }, error => {
          cbe(error);
        })
    }
  }

  getFilteredData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteGridData(gridDataUrl, cbs, cbe) {
    this.http.delete(gridDataUrl, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteexcluidGridData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteSelectedGridData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteFilterGridData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteAllGridData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteAllGridDataBill(url, cbs, cbe) {
    this.http.delete(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  setViewData(data) {
    this.gridViewData = data;
  }

  getViewData() {
    return this.gridViewData;
  }

  clearViewData() {
    return this.gridViewData = {};
  }

  //OMS start
  getOmsApprovedDeleteUpdate(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getOMSAllocatedData(Url, selectedData, cbs, cbe) {
    this.http.get(Url + selectedData)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getAllocateOrderList(Url, cbs, cbe) {
    this.http.get(Url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  OrderSendEmailForApprovalList(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  ConfirmUndoOrderurlList(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  OrderApproveList(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  fetchTradeAllocationRecords(Url, cbs, cbe) {
    this.http.get(Url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  createAllocateOrder(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }
  //OMS end

  getConfigFields(getConfigDataUrl, customData, cbs, cbe) {
    this.http.get(getConfigDataUrl + customData)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getSMNames(Url, cbs, cbe) {
    this.http.get(Url)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getUploadData(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  uploadFile(file): Observable<any> {
    return this.http.post<any>('tradeUpload/import', file);
  }

  getBrokerAccountrList(url, cbs, cbe) {
    this.http.get(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  submitPeriodicLockDate(url, cbs, cbe) {
    this.http.get(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  submitTradeEntry(url, cbs, cbe) {
    this.http.put(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  refershRunOpenTaxlot(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getCustodianList(url, cbs, cbe) {
    this.http.get(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getTickerList(url, cbs, cbe) {
    this.http.get(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  deleteByCriteria(url, data, cbs, cbe) {
    this.httpOptions['body'] = data;
    this.http.delete<any>(url, data)
    this.http.delete(url, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getAllFundByEnterprise(gridDataUrl, cbs, cbe) {
    this.http.get(gridDataUrl, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  setupbondintrestauto(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  downloadFile(gridDataUrl, data, cbs, cbe) {
    let header = this.httpOptions;
    header['responseType'] = 'arraybuffer';
    this.http.post<any>(gridDataUrl, data, header)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getGridHistoricalData(data: any): Observable<any> {
    return this.http.post('configurations/filterWorkflowByKeys', data);
  }

  getLookupValuesByTypeAndEnterprise(data: any): Observable<any> {
    return this.http.post('lookupV2/getLookupValuesByTypeAndEnterprise', data);
  }

  getProcessLogPost(data: any): Observable<any> {
    return this.http.post('processLogV2/records', data);
  }

  getGridJeSummaryReport(data: any): Observable<any> {
    return this.http.post('jesummaryreportV2/WithoutTickerSymbol/' + data.page, data);
  }

  getDuplicateInvestor(data: any,page): Observable<any> {
    return this.http.post('investor/fetchAllInvestors/' + page, data);
  }

  getGridAccountSummaryReconcile(data: any, page): Observable<any> {
    return this.http.post('bankReconciliation/getJEsByBankReconConfigInput/' + page, data);
  }
  getMappingDeatils(data: any, page, url): Observable<any> {
    return this.http.post<any>(url + page, data);
  }
  getTaxAdjustmentDeatils(data: any, url): Observable<any> {
    return this.http.post<any>(url, data);
  }
  getchartofAccountTaxDeatils(url): Observable<any> {
    return this.http.get<any>(url);
  }
  saveAccountSummaryReconcileData(data): Observable<any> {
    return this.http.post<any>('bankReconciliation/reconcileProcess', data);
  }

  getChartOfAccounts(): Observable<any> {
    return this.http.get<any>('chartOfAccountsV2/fetchAllRecords');
  }

  saveMemoEntryData(data): Observable<any> {
    return this.http.post<any>('bankReconciliation/createMemoEntry', data);
  }

  updateMemoEntryData(data): Observable<any> {
    return this.http.post<any>('bankReconciliation/updateMemoEntry', data);
  }

  updateEnterpriseStatus(data): Observable<any> {
    return this.http.put<any>('enterpriseV2/update', data);
  }

  updateAllUserStatus(data: any): Observable<any> {
    return this.http.put<any>('userV2/update', data);
  }

  getSourceConfigDownloadFilterData(data: any): Observable<any> {
    let header = this.httpOptions;
    header['responseType'] = 'arraybuffer';
    return this.http.post<any>('systemManager/alteryx/sourceConfigurationV2/exportFilteredRecords', data, header);
  }

  headerReset() {
    delete this.httpOptions['responseType']
  }

  getStartEndDate(val: any) {
    let temp = val.split('LHRXJ10EWRdateZRHXJ10JFK');
    let startDate = new Date(temp[0]);
    let endDate = new Date(temp[1]);
    return { "processEpochDate": { 'startDate': moment(startDate).format('x'), 'endDate': moment(endDate).format('x') } };
  }

  fetchLiveDetails() {
    return [
      { id: 'exchangeName', controlType: 2, label: 'exchange', viewname: 'exchangeName', name: 'exchangeName', order: 1, value: '', required: true, appearance: "fill", fill: this.exchangeList, readonly: false, class: "col-lg-3 workflowheadinput custominput" },
      { id: 'clientName', controlType: 2, label: 'client_name', name: 'clientName', viewName: 'clientName', order: 2, value: '', required: true, appearance: "fill", disabled: false, class: "col-lg-3 workflowheadinput custominput", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 2, value: null, required: true, class: "col-lg-3 workflowheadinput custominput" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: null, required: true, class: "col-lg-3 workflowheadinput custominput" }
    ]
  }

  /**
   * Function that retuns filter form for FS config FSA-395
   * @author   Ashok Kumar
   * @name     FSDetails
   * @return   {Array<any>} grid filter params 
   */
  FSDetails(): Array<any> {
    return [
      { id: 'fundName', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 1, value: null, required: false, appearance: "fill", fill: this.fundList, readonly: false, class: "col-lg-4 mb-c" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-4 mb-c" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-4 mb-c" },
    ];
  }

  archiveFS(configId): Observable<any> {
    return this.http.get<any>('fSConfig/archiveFSConfig/' + configId);
  }
  unArchiveFS(configId): Observable<any> {
    return this.http.get<any>('fSConfig/unArchiveFSConfig/' + configId);
  }

  lockFSConfig(req): Observable<any> {
    return this.http.get<any>('fSConfig/lockFSConfig/' + req['configId'] + '/' + req['locked']);
  }
  unlockFSConfig(req): Observable<any> {
    return this.http.get<any>('fsConfigLocked/unlockFSConfig/' + req);
  }

  commonDropdownDetails() {
    return new Promise(resolve => {
      this.getCustomer().subscribe((response1) => {
        this.exchangeList = response1.map(element => ({ label: element.name, value: { "id": element._id, "name": element.name } }))
        let fetchLiveDetails = this.fetchLiveDetails();
        resolve(fetchLiveDetails);
      })
    })
  }

  commonFSDropdownDetails() {
    return new Promise(resolve => {
      this.fetchFund().subscribe((response) => {
        let FSDetailsData = this.FSDetails();
        let result = response.resultList && response.resultList.length ? response.resultList : [];
        this.fundList = result.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
        FSDetailsData[0].fill = this.fundList;
        resolve(FSDetailsData);
      })
    })
  }

  /**
   * Function that grid filter params based on form selection FSA-395
   * @author   Ashok Kumar
   * @name     getFilteredReq
   * @param    {Object} formData formData selection
   * @return   {Object} grid filter params 
   */
  getFilteredReq(formData: any): Object {
    let formDataTemp = JSON.parse(JSON.stringify(formData));
    Object.keys(formData).forEach((key) => {
      if (formData[key] === "" || formData[key] === null || (typeof (formData[key]) == 'object' && Object.keys(formData[key]).length === 0)) {
        delete formData[key];
      }
    });
    Object.keys(formData).forEach(x => {
      switch (x) {
        case "startDate":
          formData[x] = this.commonService.getStartDate(formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(formDataTemp['startDate']) : this.commonService.getEndDate(formDataTemp['endDate']))
          break;
        case "endDate":
          formData[x] = (formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(formDataTemp['endDate']) : this.commonService.getStartDate(formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(formDataTemp['endDate'])
          break;
        case "fundName":
          formData[x] = this.getFundName(formData[x]);
          break;
      }
    });
    return formData;
  };

  /**
   * Function that returnd fund name based on selection FSA-395
   * @author   Ashok Kumar
   * @name     getFundName
   * @param    {string} id fundId
   * @return   {string} fundName
   */
  getFundName(id: string): string {
    let fundName;
    let fundList = this.fundList;
    fundList.forEach(el => {
      if (el.value == id) {
        fundName = el.label
      }
    })
    return fundName;
  }

  /**
   * Function that returnd date selction for filter api FSA-395
   * @author   Ashok Kumar
   * @name     createdateSet
   * @param    {string} date date
   * @return   {object} formated date
   */
  createdateSet(date: any, type: string): object {
    let splitDate = date.split('LHRXJ10EWRdateZRHXJ10JFK');
    let startDate = moment(splitDate[0]).format('YYYY-MM-DD');
    let endDate = moment(splitDate[1]).format('YYYY-MM-DD');
    let req = { type: 'contains', dateFrom: type == 'startDate' ? startDate : endDate };
    return req;
  }

  fetchFund() {
    return this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
  }

  getCustomer() {
    this.currentUser = JSON.parse(this.store.getItem('fss'));
    return this.http.get<any>('api/clients/enterpriseId/' + this.currentUser['enterpriseId']);
  }

  getClientName(clientId, enterpriseId) {
    let response1 = this.http.get<any>('api/clients/getEnterpriseUser/' + clientId + '/users/' + enterpriseId,);
    return response1;
  }

  updateWorkFlow(data) {
    return this.http.post<any>("reportDeliveryWorkflowV2/enterprise/workFlow/updateWorkflow", data)
  }

  sendReDistributionEmail(data) {
    return this.http.post<any>("isdreport/removeISDForSelectedInvestors", data)
  }

  getIsdReportResponseDTO(data): Observable<any> {
    return this.http.post<any>('isdreport/getIsdReportResponseDTO', data);
  }

  getIsdConsolidateReportResponseDTO(data): Observable<any> {
    return this.http.post<any>('isdConsolidateReport/getIsdConsolidateReportResponseDTO', data);
  }

  batchActivity(url, data): Observable<any> {
    return this.http.post<any>(url, data);
  }

  getDocumentList(data): Observable<any> {
    return this.http.post<any>("document/getDocumentList", data)
  }

  getINvestorDocumentList(data): Observable<any> {
    return this.http.post<any>("isddocument/getDocumentList", data)
  }

  deleteDocument(data): Observable<any> {
    return this.http.post<any>("document/deleteDocument", data)
  }

  downloadDocument(data): Observable<any> {
    return this.http.post<any>("document/downloadDocument", data)
  }

  previewIsdReportResponseDTOList(data): Observable<any> {
    return this.http.post<any>('isdreport/previewIsdReportResponseDTOList', data);
  }

  downloadAttachment(data): Observable<any> {
    return this.http.post<any>('emailLogDashboard/downloadAttachment', data);
  } 
 

  previewIsdGroupReportResponseDTOList(data): Observable<any> {
    return this.http.post<any>('isdConsolidateReport/previewIsdReportResponseDTOList', data);
  }

  getREfundNameByLevel(data) {
    return this.http.post<any>('fundV2/getFundNameByEntityGroup', data);
  }

  getEntity() {
    return this.http.get<any>('fundV2/fetchAllByEnterprise');
  }

  getEntityfilterByCriteria(data) {
    return this.http.post<any>('bankReconciliation/filterByCriteria', data);
  }

  getSubmissions() {
    return this.http.get<any>('financialDataIntegration/getSubmissions');
  }

  filterCheckerApprovedWorkflowByFundIds(data) {
    return this.http.post<any>('reportDeliveryWorkflowV2/filterCheckerApprovedWorkflowByFundIds', data);
  }

  addISDConsolidateWorkflowConfigurationDTO(data) {
    return this.http.post<any>('isdConsolidateWorkflow/addISDConsolidateWorkflowConfigurationDTO', data);
  }

  updateConsolidateWorkflow(data) {
    return this.http.post<any>("reportDeliveryWorkflowV2/updateConsolidateWorkflow", data)
  }

  isExists(data): Observable<any> {
    return this.http.post<any>('bankReconciliation/recordExist', data);
  }

  checkMultiAuth(auth, authList) {
    let isAuth = true
    if (auth) {
      let list = auth.split(',')
      isAuth = list.some(item => authList.includes(item))
    }
    return isAuth
  }

  previewIsdAdhocReportResponseDTOList(data): Observable<any> {
    return this.http.post<any>('isdAdhocReport/previewIsdAdhocReportResponseDTOList', data);
  }

  adhocMarkNaSelectedInvestors(data) {
    return this.http.post<any>("isdAdhocReport/markNaSelectedInvestors", data)
  }

  approveOrRejectAdhocConfiguration(data) {
    return this.http.post<any>("isdAdhocDistributionConfig/approveOrRejectAdhocConfiguration", data)
  }

  updateDocumentList(data): Observable<any> {
    return this.http.post<any>("document/updateDocumentList", data)
  }

  /**
   * update investor request for FS-investor FSA-425
   * @author   Ashok Kumar
   */
  refreshFSInvestorList(data) {
    return this.http.post<any>('fSInvestor/create', data);
  }

  refreshFSPortfolioList(data) {
    return this.http.post<any>('fSPortfolio/create', data);
  }
  refreshFsChartOfAccount(data) {
    return this.http.post<any>('fsChartOfAccounts/create', data);
  }
  copyChartOfAccountsConfig(data) {
    return this.http.post<any>('fsChartOfAccounts/copyChartOfAccountsConfig', data);
  }
  copyFSPositionConfig(data) {
    return this.http.post<any>('fSPortfolio/copyFSPositionConfig', data);
  }
  refreshFsCashAndCashEquivalent(data) {
    return this.http.post<any>('cashAndCashEquivalent/create', data);
  }
  
  refreshFsExposure(data) {
    return this.http.post<any>('fsExposure/create', data);
  }
  refreshDataSequentially(fsChartOfAccountData: object,
    fsPortfolioData: object,
    fsInvestorData: object): Observable<any> {
    return this.refreshFsChartOfAccount(fsChartOfAccountData).pipe(
      switchMap(() => this.refreshFSPortfolioList(fsPortfolioData)),
      switchMap(() => this.refreshFSInvestorList(fsInvestorData))
    );
  }

  // editable grid
  getEditableAPI(list, nodeParam) {
    let apiList = [];
    list.forEach(item => {
      let response;
      let params = item.params;
      let postParams = item.postParams;
      if (item.type == 'get') {
        let paramsObj = {};
        if (params) {
          let nodeData = nodeParam.data;
          params.forEach(element => {
            let paramData;
            if (element.dataKey && element.dataSubKey) {
              paramData = nodeData[element.dataKey][element.dataSubKey];
            } else {
              paramData = nodeData[element.dataKey];
            }
            paramsObj[element.param] = paramData;
          });
          response = this.http.get<any>(item.url, { params: paramsObj });
        } else {
          response = this.http.get<any>(item.url);
        }
        apiList.push(response);
      } else {
        let data = item.data;
        if (data) {
          response = this.http.post<any>(item.url, data, this.httpOptions);
        } else if (postParams) {
          let postParamsObj = {};
          if (postParams) {
            let nodeData = nodeParam.data;
            postParams.forEach(element => {
              let paramData;
              if (element.dataKey && element.dataSubKey) {
                paramData = nodeData[element.dataKey][element.dataSubKey];
              } else {
                paramData = nodeData[element.dataKey];
              }
              postParamsObj[element.param] = paramData;
            });
            response = this.http.post<any>(item.url, postParamsObj, this.httpOptions);
          }
        }
        else {
          response = this.http.post<any>(item.url, this.httpOptions);
        }
        apiList.push(response);
      }
    });
    return forkJoin(apiList);
  }

  updateData(url, data, type): Observable<any> {
    return type == 'put' ? this.http.put<any>(url, data) : this.http.post<any>(url, data);
  }
}
